import React from 'react'
import { ChatItemType } from 'constants/ChatItemType'
import ReactSelect from "../common/ReactSelect";
import {ChatActionType} from "../../constants/ChatActionType";
import HabitsSelector from "../habits/HabitsSelector";
import Modal from "react-responsive-modal";
import ChallengesSelector from "../challenges/ChallengesSelector";
import UUID from "../../utils/UUID";
import {DayTime} from "../../constants/DayTime";
import ChatFlowsSelector from "./ChatFlowsSelector";
import ChatCustomVariablesSelector from "./ChatCustomVariablesSelector";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {NotificationManager} from "react-notifications";
import Utils from "../../utils/Utils";
import DatePicker from 'components/common/DatePicker'
import DateHelper from 'utils/DateHelper'
import ChatNotificationsSelector from "./ChatNotificationsSelector";
import ChatVariablesSelector from "./ChatVariablesSelector";
import {ConversationSelector} from "./conversations/ConversationSelector";
import {PermissionUtil} from "../../utils/PermissionUtil";

export default class ChatActionForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: Object.assign({
                action: null,
                actionText: null,
                actionItem: null,
                actionValue: null,
                actionItemTitle: null,
                actionTextFieldID: null,
                actionUseUserInput: 0,
                actionTHAnchorUseUserInput: 1,
                actionTHBehaviourUseUserInput: 1,
                actionTHCelebrationUseUserInput: 1,
                actionTHAnchorFixedValue: null,
                actionTHBehaviourFixedValue: null,
                actionTHCelebrationFixedValue: null,
                actionTHAnchorFieldID: null,
                actionTHBehaviourFieldID: null,
                actionTHCelebrationFieldID: null,
                actionHabit: null,
                actionHabitTitle: null,
                actionUseSelectedHabit: 0,
                actionAddReminder: 0,
                actionUseUserInputAsReminder: 0,
                actionReminderTextFieldID: null,
                actionReminderTime: null,
                actionSaveJournalRecordWithQuestion: 0,
                actionNotification: null,
                actionRunForDays: 7,
                actionAIConvUserText: '',
                actionAIConvAIText: '',
                actionAIConvCategory: '',
                day: 0,
                dayTime: null,
                nodeText: null,
                typeText: "Action",
                typeID: ChatItemType.ACTION,
                uuid: UUID.uuidv4()
            }, props.node),
            validated: false,
            actionOptions: ChatActionType.getOptions(),
            errors: {},
            touched: {},
            isLoadingEmotions: false,
            emotionOptions: [],
            modal: {
                isOpen: false,
                content: null
            }
        };
    }

    componentDidMount() {
        this.validate();
        this.getEmotionOptions();
    }


    handleBlur = (event) => {
        const target = event.target;
        const name = target.name;

        let touched = this.state.touched;
        touched[name] = true;

        this.setState({
            touched: touched
        }, () => {
            this.validate()
        })
    }

    getEmotionOptions = () => {

        if (!this.state.isLoadingEmotions) {

            this.setState({
                isLoadingEmotions: true
            }, () => {

                Axios
                    .get(API.chat.getEmotions)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({
                                isLoadingEmotions: false,
                                emotionOptions: res.data.data.emotions.map((item) => { return {value: item.id.toString(), label: item.name} })
                            })
                        }
                        else {
                            NotificationManager.error(res.data.error.desc);
                            this.setState({
                                isLoadingEmotions: false
                            })
                        }
                    });

            })

        }


    }

    handleChange = (event) => {

        const target = event.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        let name = target.name;

        let item = Object.assign({}, this.state.item);

        if (name === "action") {
            item.actionItem = null;
            item.actionItemTitle = null;
            item.actionValue = null;
            item.nodeText = null;
            item.dayTime = null;
            item.journalNote = null;
            let option = this.state.actionOptions.find(element => element.value === value );
            if (option) {
                value = option;
            }
        }

        item[name] = value;

        if (item.action && item.actionItem && item.action.value === ChatActionType.MOOD_RECORD) {
            item.actionItemTitle = this.state.emotionOptions.find((eItem) => eItem.value === item.actionItem).label;
        }
        else if (item.action && item.actionItem && item.action.value === ChatActionType.JOURNAL_RECORD) {
            item.actionItemTitle = item.actionItem;
        }

        if (item.action && item.action.value) {
            item.nodeText = ChatActionType.getName(item.action.value);
            if (item.actionItemTitle || item.actionValue) {
                item.nodeText += " - ";
            }
            if (item.actionItemTitle) {
                item.nodeText += this.state.item.actionItemTitle;
                if (item.actionValue) {
                    item.nodeText += " : ";
                }
            }
            if (item.actionValue) {
                item.nodeText += item.actionValue;
            }
        }
        else {
            item.nodeText = "";
        }

        this.setState({
            item: item
        }, () => {
            this.validate();
        });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.onSubmitted instanceof Function) {
            this.props.onSubmitted(this.state.item)
        }
    }


    validate = () => {

        let validated = true;
        let errors = {};

        // const item = this.state.item;
        // const touched = this.state.touched;
        //
        // if (item.action) {
        //     if (! item.actionItem) {
        //         validated = false;
        //     }
        //     else {
        //         if (item.action.value === ChatActionType.UPDATE_CUSTOM_PROPERTY && (!item.actionValue || item.actionValue.length === 0)) {
        //             validated = false;
        //             if (touched.actionValue) {
        //                 errors.actionValue = "Value is required"
        //             }
        //         }
        //     }
        // }
        // else {
        //     validated = false;
        // }

        this.setState({
            validated: validated,
            errors: errors
        });
    }

    showHabitModal = (actionType) => {
        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.actionItem) {
            selected.push({id: this.state.item.actionItem.id})
        }

        const content = (
            <HabitsSelector
                organisationID={this.state.item.organisationID}
                isSingleSelection
                selected={selected}
                onSubmitted={(habit) => {

                    let item = Object.assign({}, this.state.item);

                    if (actionType === ChatActionType.TINY_HABIT || actionType === ChatActionType.HABIT_MID_TARGET || actionType === ChatActionType.HABIT_GOAL) {
                        item.actionHabit = habit;
                        item.actionHabitTitle = habit.name;
                        //TODO item nodeText
                    }
                    else {
                        item.actionItem = habit;
                        item.actionItemTitle = habit.name;
                        item.nodeText = ChatActionType.getName(actionType) + " - " + habit.name;
                    }

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }


    showVariablesSelector = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showCustomVariablesSelectorForText = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={true}
                showSelectButton={false}
                showCondVariables={false}
                showTextVariables={true}
                onSelected={() => this.closeModal()}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showCustomVariablesSelector = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ChatCustomVariablesSelector
                showCopyButton={false}
                showSelectButton={true}
                onSelected={(variable) => {
                    let item = Object.assign({}, this.state.item);
                    item.actionItem = variable;
                    item.actionItemTitle = variable.key;
                    item.nodeText = ChatActionType.getName(ChatActionType.UPDATE_CUSTOM_PROPERTY) + " - " + this.state.item.actionItemTitle;
                    console.log(variable);
                    this.setState({
                        item: item
                    }, () => {
                        this.closeModal();
                        this.validate();
                    })
                }}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-medium"
            }
        }, () => {
            this.validate();
        })
    }

    showChallengeModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.actionItem) {
            selected.push({id: this.state.item.actionItem.id})
        }

        const content = (
            <ChallengesSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(challenge) => {

                    console.log(challenge);

                    let item = Object.assign({}, this.state.item);
                    item.actionItem = challenge;
                    item.actionItemTitle = challenge.title;
                    item.nodeText = ChatActionType.getName(ChatActionType.JOIN_CHALLENGE) + " - " + challenge.title;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }

    showNotificationsModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.actionItem) {
            selected.push({id: this.state.item.actionItem.id})
        }

        const content = (
            <ChatNotificationsSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(selectedItem) => {

                    let item = Object.assign({}, this.state.item);
                    item.actionItem = selectedItem;
                    item.actionItemTitle = selectedItem.name;
                    item.nodeText = ChatActionType.getName(this.state.item.action.value) + " - " + selectedItem.name;

                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-large"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        }, () => {
            this.validate();
        })
    }

    showChatFlowModal = () => {

        if (this.state.modal.isOpen) {
            return;
        }

        let selected = [];
        if (this.state.item.service) {
            selected.push({id: this.state.item.service.id})
        }

        const content = (
            <ChatFlowsSelector
                isSingleSelection
                selected={selected}
                onSubmitted={(chatFlow) => {

                    let item = Object.assign({}, this.state.item);
                    item.actionItem = chatFlow;
                    item.actionItemTitle = chatFlow.title;
                    item.nodeText = ChatActionType.getName(ChatActionType.ADD_QUICK_CHAT_ACCESS) + " - " + chatFlow.title;


                    this.setState({
                        item: item,
                        modal: {
                            isOpen: false,
                            content: null,
                            size: "modal-medium"
                        }
                    }, () => {
                        this.validate();
                    })
                }}
                onCancel={this.closeModal}
            />
        );

        this.setState({
            modal: {
                isOpen: true,
                content: content,
                size: "modal-large"
            }
        })
    }

    closeModal = (completion) => {
        if (this.state.modal.isOpen) {
            this.setState({
                modal: {
                    isOpen: false,
                    content: null
                }
            }, () => {
                if (completion instanceof Function) {
                    completion();
                }
            })
        }
    }

    showConversations = () => {
        if (this.state.modal.isOpen) {
            return;
        }

        const content = (
            <ConversationSelector
                showCopyButton={false}
                showSelectButton={true}
                showEditButton={PermissionUtil.edit(PermissionUtil.PermissionCategories.conversationTypes)}
                showDeleteButton={PermissionUtil.delete(PermissionUtil.PermissionCategories.conversationTypes)}
                onSelected={(conv) => {
                    let item = Object.assign({}, this.state.item);
                    item.actionAIConvCategory = conv;
                    this.setState({
                        item: item
                    });
                    this.closeModal();
                }}
                onCancel={() => this.closeModal()}
            />
        );

        this.setState({
            modal : {
                isOpen : true,
                content,
                size : "modal-medium"
            }
        });
    }

    render() {

        return (
            <React.Fragment>
                <div className="modal-head">
                    <h3>Chat action</h3>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">

                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="action" className="text-right middle">Action</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.action && "error")}>
                                <ReactSelect
                                    name="action"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.action}
                                    options={this.state.actionOptions}
                                />
                                {this.state.item.action &&
                                <small className="field-info">{ChatActionType.getDescription(this.state.item.action.value)}</small>
                                }
                                <small className="error">{this.state.errors.action}</small>
                            </div>
                        </div>

                        {this.state.item.action && this.state.item.action.value === ChatActionType.SCHEDULE_HABIT &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="dayTime" className="text-right middle">Day Time</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.dayTime && "error")}>
                                <ReactSelect
                                    name="dayTime"
                                    className="react-select"
                                    onChange={this.handleChange}
                                    value={this.state.item.dayTime}
                                    options={DayTime.getSelectOptions()}
                                />
                                <small className="error">{this.state.errors.dayTime}</small>
                            </div>
                        </div>
                        }

                        {this.state.item.action && (this.state.item.action.value === ChatActionType.ADD_HABIT || this.state.item.action.value === ChatActionType.ADD_COMPLETED_HABIT || this.state.item.action.value === ChatActionType.SCHEDULE_HABIT) &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="actionItem" className="text-right middle">Select Habit</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.actionItem && "error")}>
                                <div className="input-group">
                                    <input className="input-group-field" name="actionItem" type="text" value={this.state.item.actionItemTitle || "Not selected" } disabled/>
                                    <div className="input-group-button">
                                        <button type="button" className="button" onClick={() => this.showHabitModal(this.state.item.action.value)}>Browse</button>
                                    </div>
                                </div>
                                <small className="error">{this.state.errors.actionItem}</small>
                            </div>
                        </div>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.ADD_AI_CONVERSATION &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionAIConvCategory" className="text-right middle">Select Conversation</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionItem && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="actionAIConvCategory" type="text" value={this.state.item.actionAIConvCategory ? this.state.item.actionAIConvCategory.name : "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showConversations()}>Pick Conversation</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.actionItem}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionAIConvUserText" className="text-right middle">User text</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionAIConvUserText && "error")}>
                                    <input name="actionAIConvUserText" type="text" value={this.state.item.actionAIConvUserText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionAIConvUserText}</span>
                                    <small className="field-info">Optional. Can be left empty. If not set, AI Text must be provided.</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionAIConvAIText" className="text-right middle">AI Text</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionAIConvAIText && "error")}>
                                    <input name="actionAIConvAIText" type="text" value={this.state.item.actionAIConvAIText} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionAIConvAIText}</span>
                                    <small className="field-info">Optional. Can be left empty. If not set, User Text must be provided.</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="text" className="text-right middle">System properties</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                    <button className="button primary" type="button"
                                            onClick={this.showVariablesSelector}>Show properties
                                    </button>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="text" className="text-right middle">Custom properties</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.text && "error")}>
                                    <button className="button primary" type="button"
                                            onClick={this.showCustomVariablesSelectorForText}>Show properties
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.CLEAR_AI_CONVERSATION &&
                            <React.Fragment>
                                <div className="grid-x grid-padding-x">
                                    <div className="small-3 cell">
                                        <label htmlFor="actionAIConvCategory" className="text-right middle">Select Conversation</label>
                                    </div>
                                    <div className={"large-9 small-9 cell " + (this.state.errors.actionItem && "error")}>
                                        <div className="input-group">
                                            <input className="input-group-field" name="actionAIConvCategory" type="text" value={this.state.item.actionAIConvCategory ? this.state.item.actionAIConvCategory.name : "Not selected" } disabled/>
                                            <div className="input-group-button">
                                                <button type="button" className="button" onClick={() => this.showConversations()}>Pick Conversation</button>
                                            </div>
                                        </div>
                                        <small className="error">{this.state.errors.actionItem}</small>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {this.state.item.action && (this.state.item.action.value === ChatActionType.ADD_HABIT || this.state.item.action.value === ChatActionType.ADD_COMPLETED_HABIT) &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="day" className="text-right middle">Day</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.day && "error")}>
                                <input name="day" type="number" value={this.state.item.day} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                <small className="error">{this.state.errors.day}</small>
                                <small className="field-info">Please enter 0 for user's current day, -1 for yesterday, 1 for tomorrow etc.</small>
                            </div>
                        </div>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.SCHEDULE_HABIT &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionAddReminder" className="text-right middle">Add reminder</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionAddReminder" onChange={this.handleChange} checked={this.state.item.actionAddReminder || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.actionAddReminder === 1 &&
                                <React.Fragment>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="actionUseUserInputAsReminder" className="text-right middle">Reminder from user's input</label>
                                        </div>
                                        <div className="large-4 small-9 cell">
                                            <label className="chkbx-container">
                                                <input className="chkbx" type="checkbox" name="actionUseUserInputAsReminder" onChange={this.handleChange} checked={this.state.item.actionUseUserInputAsReminder || 0 === 1} />
                                                <span className="chkmk"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.item.actionUseUserInputAsReminder === 1 ?
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="actionReminderTextFieldID" className="text-right middle">* Reminder Text field ID</label>
                                            </div>
                                            <div className={"large-9 small-9 cell " + (this.state.errors.actionReminderTextFieldID && "error")}>
                                                <input type="text" name="actionReminderTextFieldID" value={this.state.item.actionReminderTextFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored. Please make sure the selected text field uses Time validation. If submitted value is not a valid time the reminder would not be set.</small>
                                                <small className="error">{this.state.errors.actionReminderTextFieldID}</small>
                                            </div>
                                        </div>
                                        :
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="actionReminderTime" className="text-right middle">* Reminder Time</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.actionReminderTime && "error")}>
                                                <DatePicker
                                                    name="actionReminderTime"
                                                    data-enable-time
                                                    value={this.state.item.actionReminderTime}
                                                    onChange={this.handleChange}
                                                    options={{
                                                        noCalendar: true,
                                                        time_24hr: true,
                                                        dateFormat: "H:i",
                                                        altFormat: "H:i",
                                                        altInput: true,
                                                        static: true
                                                    }}
                                                />
                                                <small className="error">{this.state.errors.actionReminderTime}</small>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.JOIN_CHALLENGE &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="actionItem" className="text-right middle">Select Challenge</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.actionItem && "error")}>
                                <div className="input-group">
                                    <input className="input-group-field" name="actionItem" type="text" value={this.state.item.actionItemTitle || "Not selected" } disabled/>
                                    <div className="input-group-button">
                                        <button type="button" className="button" onClick={() => this.showChallengeModal()}>Browse</button>
                                    </div>
                                </div>
                                <small className="error">{this.state.errors.actionItem}</small>
                            </div>
                        </div>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.ADD_QUICK_CHAT_ACCESS &&
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="actionItem" className="text-right middle">Select Chat Flow</label>
                            </div>
                            <div className={"large-9 small-9 cell " + (this.state.errors.actionItem && "error")}>
                                <div className="input-group">
                                    <input className="input-group-field" name="actionItem" type="text" value={this.state.item.actionItemTitle || "Not selected" } disabled/>
                                    <div className="input-group-button">
                                        <button type="button" className="button" onClick={() => this.showChatFlowModal()}>Browse</button>
                                    </div>
                                </div>
                                <small className="error">{this.state.errors.actionItem}</small>
                            </div>
                        </div>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.UPDATE_CUSTOM_PROPERTY &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionItem" className="text-right middle">Select Custom Property</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionItem && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" type="text" value={this.state.item.actionItemTitle || "Not selected" }  disabled/>
                                        <div className="input-group-button">
                                            <button type="button" style={{marginLeft: 2}} className="button" onClick={this.showCustomVariablesSelector}>Select</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.actionItem}</small>
                                </div>
                            </div>

                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="action" className="text-right middle">* Value</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionValue && "error")}>
                                    <input name="actionValue" type="text" value={this.state.item.actionValue} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionValue}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.MOOD_RECORD &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionItem" className="text-right middle">Select Emotion</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionItem && "error")}>
                                    <ReactSelect
                                        name="actionItem"
                                        className="react-select"
                                        onChange={this.handleChange}
                                        value={this.state.item.actionItem}
                                        options={this.state.emotionOptions}
                                    />
                                    <small className="error">{this.state.errors.actionItem}</small>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionUseUserInput" className="text-right middle">Use User input as note</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionUseUserInput" onChange={this.handleChange} checked={this.state.item.actionUseUserInput || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.actionUseUserInput !== 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionValue" className="text-right middle">* Note</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionValue && "error")}>
                                    <input name="actionValue" type="text" value={this.state.item.actionValue} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionValue}</span>
                                </div>
                            </div>
                            }
                            {this.state.item.actionUseUserInput === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTextFieldID" className="text-right middle">* Text field ID</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTextFieldID && "error")}>
                                    <input type="text" name="actionTextFieldID" value={this.state.item.actionTextFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored.</small>
                                    <small className="error">{this.state.errors.actionTextFieldID}</small>
                                </div>
                            </div>
                            }
                        </React.Fragment>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.JOURNAL_RECORD &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionUseUserInput" className="text-right middle">Use User input as note</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionUseUserInput" onChange={this.handleChange} checked={this.state.item.actionUseUserInput || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.actionUseUserInput !== 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionItem" className="text-right middle">Note</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionItem && "error")}>
                                    <input name="actionItem" type="text" value={this.state.item.actionItem} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionItem}</span>
                                </div>
                            </div>
                            }
                            {this.state.item.actionUseUserInput === 1 &&
                                <React.Fragment>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="actionTextFieldID" className="text-right middle">* Text field ID</label>
                                        </div>
                                        <div className={"large-9 small-9 cell " + (this.state.errors.actionTextFieldID && "error")}>
                                            <input type="text" name="actionTextFieldID" value={this.state.item.actionTextFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                            <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored.</small>
                                            <small className="error">{this.state.errors.actionTextFieldID}</small>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="actionSaveJournalRecordWithQuestion" className="text-right middle">Save with text field's prompt</label>
                                        </div>
                                        <div className="large-4 small-9 cell">
                                            <label className="chkbx-container">
                                                <input className="chkbx" type="checkbox" name="actionSaveJournalRecordWithQuestion" onChange={this.handleChange} checked={this.state.item.actionSaveJournalRecordWithQuestion || 0 === 1} />
                                                <span className="chkmk"></span>
                                            </label>
                                            <small className="field-info">If checked, the note will be saved together with the text field's prompt from the field referenced above.</small>
                                        </div>
                                    </div>
                                </React.Fragment>

                            }

                        </React.Fragment>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.TINY_HABIT &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionUseSelectedHabit" className="text-right middle">Update selected habit</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionUseSelectedHabit" onChange={this.handleChange} checked={this.state.item.actionUseSelectedHabit || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                    <small className="field-info">If checked, the system will attempt to update manually selected habit otherwise it would update habit submitted from the mobile application.</small>
                                </div>
                            </div>
                            {this.state.item.actionUseSelectedHabit === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionItem" className="text-right middle">Select Habit</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionHabit && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="actionItem" type="text" value={this.state.item.actionHabitTitle || "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showHabitModal(this.state.item.action.value)}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.actionHabit}</small>
                                </div>
                            </div>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHAnchorUseUserInput" className="text-right middle">Anchor Use User input</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionTHAnchorUseUserInput" onChange={this.handleChange} checked={this.state.item.actionTHAnchorUseUserInput || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.actionTHAnchorUseUserInput !== 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHAnchorFixedValue" className="text-right middle">* Anchor text</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTHAnchorFixedValue && "error")}>
                                    <input name="actionTHAnchorFixedValue" type="text" value={this.state.item.actionTHAnchorFixedValue} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionTHAnchorFixedValue}</span>
                                    <small className="field-info">System and custom properties are allowed.</small>
                                </div>
                            </div>
                            }
                            {this.state.item.actionTHAnchorUseUserInput === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHAnchorFieldID" className="text-right middle">* Anchor Text field ID</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTHAnchorFieldID && "error")}>
                                    <input type="text" name="actionTHAnchorFieldID" value={this.state.item.actionTHAnchorFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored.</small>
                                    <small className="error">{this.state.errors.actionTHAnchorFieldID}</small>
                                </div>
                            </div>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHBehaviourUseUserInput" className="text-right middle">Behaviour Use User input</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionTHBehaviourUseUserInput" onChange={this.handleChange} checked={this.state.item.actionTHBehaviourUseUserInput || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.actionTHBehaviourUseUserInput !== 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHBehaviourFixedValue" className="text-right middle">* Behaviour text</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTHBehaviourFixedValue && "error")}>
                                    <input name="actionTHBehaviourFixedValue" type="text" value={this.state.item.actionTHBehaviourFixedValue} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionTHBehaviourFixedValue}</span>
                                    <small className="field-info">System and custom properties are allowed.</small>
                                </div>
                            </div>
                            }
                            {this.state.item.actionTHBehaviourUseUserInput === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHBehaviourFieldID" className="text-right middle">* Behaviour Text field ID</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTHBehaviourFieldID && "error")}>
                                    <input type="text" name="actionTHBehaviourFieldID" value={this.state.item.actionTHBehaviourFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored.</small>
                                    <small className="error">{this.state.errors.actionTHBehaviourFieldID}</small>
                                </div>
                            </div>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHCelebrationUseUserInput" className="text-right middle">Celebration Use User input</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionTHCelebrationUseUserInput" onChange={this.handleChange} checked={this.state.item.actionTHCelebrationUseUserInput || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.actionTHCelebrationUseUserInput !== 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHCelebrationFixedValue" className="text-right middle">* Celebration text</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTHCelebrationFixedValue && "error")}>
                                    <input name="actionTHCelebrationFixedValue" type="text" value={this.state.item.actionTHCelebrationFixedValue} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionTHCelebrationFixedValue}</span>
                                    <small className="field-info">System and custom properties are allowed.</small>
                                </div>
                            </div>
                            }
                            {this.state.item.actionTHCelebrationUseUserInput === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTHCelebrationFieldID" className="text-right middle">* Celebration Text field ID</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTHCelebrationFieldID && "error")}>
                                    <input type="text" name="actionTHCelebrationFieldID" value={this.state.item.actionTHCelebrationFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored.</small>
                                    <small className="error">{this.state.errors.actionTHCelebrationFieldID}</small>
                                </div>
                            </div>
                            }
                            {(this.state.item.actionTHCelebrationUseUserInput !== 1 || this.state.item.actionTHAnchorUseUserInput !== 1 || this.state.item.actionTHBehaviourUseUserInput !== 1) &&
                                <React.Fragment>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="text" className="text-right middle">System properties</label>
                                        </div>
                                        <div className={"large-9 small-9 cell"}>
                                            <button className="button primary" type="button" onClick={this.showVariablesSelector}>Show properties</button>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="text" className="text-right middle">Custom properties</label>
                                        </div>
                                        <div className={"large-9 small-9 cell "}>
                                            <button className="button primary" type="button" onClick={this.showCustomVariablesSelector}>Show properties</button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }

                        </React.Fragment>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.HABIT_MID_TARGET &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionUseSelectedHabit" className="text-right middle">Update selected habit</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionUseSelectedHabit" onChange={this.handleChange} checked={this.state.item.actionUseSelectedHabit || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                    <small className="field-info">If checked, the system will attempt to update manually selected habit otherwise it would update habit submitted from the mobile application.</small>
                                </div>
                            </div>
                            {this.state.item.actionUseSelectedHabit === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionItem" className="text-right middle">Select Habit</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionHabit && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="actionItem" type="text" value={this.state.item.actionHabitTitle || "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showHabitModal(this.state.item.action.value)}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.actionHabit}</small>
                                </div>
                            </div>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionUseUserInput" className="text-right middle">Use User input for value</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionUseUserInput" onChange={this.handleChange} checked={this.state.item.actionUseUserInput || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.actionUseUserInput !== 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionValue" className="text-right middle">* Custom Value</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionValue && "error")}>
                                    <input name="actionValue" type="text" value={this.state.item.actionValue} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionValue}</span>
                                </div>
                            </div>
                            }
                            {this.state.item.actionUseUserInput === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTextFieldID" className="text-right middle">* Text field ID</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTextFieldID && "error")}>
                                    <input type="text" name="actionTextFieldID" value={this.state.item.actionTextFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored.</small>
                                    <small className="error">{this.state.errors.actionTextFieldID}</small>
                                </div>
                            </div>
                            }
                        </React.Fragment>
                        }
                        {this.state.item.action && this.state.item.action.value === ChatActionType.HABIT_GOAL &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionUseSelectedHabit" className="text-right middle">Update selected habit</label>
                                </div>
                                <div className="large-9 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionUseSelectedHabit" onChange={this.handleChange} checked={this.state.item.actionUseSelectedHabit || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                    <small className="field-info">If checked, the system will attempt to update manually selected habit otherwise it would update habit submitted from the mobile application.</small>
                                </div>
                            </div>
                            {this.state.item.actionUseSelectedHabit === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionItem" className="text-right middle">Select Habit</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionHabit && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="actionItem" type="text" value={this.state.item.actionHabitTitle || "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showHabitModal(this.state.item.action.value)}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.actionHabit}</small>
                                </div>
                            </div>
                            }
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionUseUserInput" className="text-right middle">Use User input for value</label>
                                </div>
                                <div className="large-4 small-9 cell">
                                    <label className="chkbx-container">
                                        <input className="chkbx" type="checkbox" name="actionUseUserInput" onChange={this.handleChange} checked={this.state.item.actionUseUserInput || 0 === 1} />
                                        <span className="chkmk"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.item.actionUseUserInput !== 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionValue" className="text-right middle">* Custom Value</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionValue && "error")}>
                                    <input name="actionValue" type="text" value={this.state.item.actionValue} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <span className="error">{this.state.errors.actionValue}</span>
                                </div>
                            </div>
                            }
                            {this.state.item.actionUseUserInput === 1 &&
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionTextFieldID" className="text-right middle">* Text field ID</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionTextFieldID && "error")}>
                                    <input type="text" name="actionTextFieldID" value={this.state.item.actionTextFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                    <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored.</small>
                                    <small className="error">{this.state.errors.actionTextFieldID}</small>
                                </div>
                            </div>
                            }
                        </React.Fragment>
                        }
                        {this.state.item.action && (this.state.item.action.value === ChatActionType.ADD_NOTIFICATION || this.state.item.action.value === ChatActionType.REMOVE_NOTIFICATION) &&
                        <React.Fragment>
                            <div className="grid-x grid-padding-x">
                                <div className="small-3 cell">
                                    <label htmlFor="actionItem" className="text-right middle">Select Notification</label>
                                </div>
                                <div className={"large-9 small-9 cell " + (this.state.errors.actionNotification && "error")}>
                                    <div className="input-group">
                                        <input className="input-group-field" name="actionItem" type="text" value={this.state.item.actionItemTitle || "Not selected" } disabled/>
                                        <div className="input-group-button">
                                            <button type="button" className="button" onClick={() => this.showNotificationsModal(this.state.item.action.value)}>Browse</button>
                                        </div>
                                    </div>
                                    <small className="error">{this.state.errors.actionNotification}</small>
                                </div>
                            </div>
                            {this.state.item.action.value === ChatActionType.ADD_NOTIFICATION &&
                                <React.Fragment>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="day" className="text-right middle">Start Day</label>
                                        </div>
                                        <div className={"large-9 small-9 cell " + (this.state.errors.day && "error")}>
                                            <input name="day" type="number" value={this.state.item.day} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                            <small className="error">{this.state.errors.day}</small>
                                            <small className="field-info">Please enter 0 for user's current day, -1 for yesterday, 1 for tomorrow etc.</small>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="actionRunForDays" className="text-right middle">Run for number of days</label>
                                        </div>
                                        <div className={"large-9 small-9 cell " + (this.state.errors.actionRunForDays && "error")}>
                                            <input name="actionRunForDays" type="number" value={this.state.item.actionRunForDays} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                            <small className="error">{this.state.errors.actionRunForDays}</small>
                                            <small className="field-info">The number of days for which notification will be send. Number must be positive otherwise action will be ignored. If set to 0, notification will not have end date and will be send every day up until it is removed from user's schedule.</small>
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-3 cell">
                                            <label htmlFor="actionUseUserInputAsReminder" className="text-right middle">Time from user's input</label>
                                        </div>
                                        <div className="large-4 small-9 cell">
                                            <label className="chkbx-container">
                                                <input className="chkbx" type="checkbox" name="actionUseUserInputAsReminder" onChange={this.handleChange} checked={this.state.item.actionUseUserInputAsReminder || 0 === 1} />
                                                <span className="chkmk"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.item.actionUseUserInputAsReminder === 1 ?
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="actionReminderTextFieldID" className="text-right middle">* Time Text field ID</label>
                                            </div>
                                            <div className={"large-9 small-9 cell " + (this.state.errors.actionReminderTextFieldID && "error")}>
                                                <input type="text" name="actionReminderTextFieldID" value={this.state.item.actionReminderTextFieldID} onBlur={this.handleBlur} onChange={this.handleChange}/>
                                                <small className="field-info">Please note, action can only be executed after user input is collected. The referenced text field must be located in the chat flow hierarchy higher than the action itself, otherwise the action will be ignored. Please make sure the selected text field uses Time validation. If submitted value is not a valid time the notification would not be set.</small>
                                                <small className="error">{this.state.errors.actionReminderTextFieldID}</small>
                                            </div>
                                        </div>
                                        :
                                        <div className="grid-x grid-padding-x">
                                            <div className="small-3 cell">
                                                <label htmlFor="actionReminderTime" className="text-right middle">* Time</label>
                                            </div>
                                            <div className={"small-9 cell " + (this.state.errors.actionReminderTime && "error")}>
                                                <DatePicker
                                                    name="actionReminderTime"
                                                    data-enable-time
                                                    value={this.state.item.actionReminderTime}
                                                    onChange={this.handleChange}
                                                    options={{
                                                        noCalendar: true,
                                                        time_24hr: true,
                                                        dateFormat: "H:i",
                                                        altFormat: "H:i",
                                                        altInput: true,
                                                        static: true
                                                    }}
                                                />
                                                <small className="error">{this.state.errors.actionReminderTime}</small>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell">
                                <div className="btn-wrap">
                                    <button className="button secondary" type="button" onClick={this.props.onCancel}>
                                        Cancel
                                    </button>
                                    <button className="button success" type="submit" disabled={!this.state.validated}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    classNames={{modal: this.state.modal.size}}
                    open={this.state.modal.isOpen}
                    modalId="ChatInnerModal"
                    onClose={this.closeModal}
                    center
                >
                    {this.state.modal.content}
                </Modal>
            </React.Fragment>
        );
    }
}
