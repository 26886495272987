import {ServiceType} from "../constants/ServiceType";
import {ChatItemType} from "../constants/ChatItemType";
import {CommonUtil} from "./CommonUtil";

export const PluginUtil = {
    serviceTypesSupported : [
        ServiceType.PAGE,
        ServiceType.PODCAST,
        ServiceType.VIDEO,
        ServiceType.WEBSITE,
        ServiceType.SSO_LOGIN
    ],
    chatFlowItemTypesSupported : [
        ChatItemType.MESSAGE,
        ChatItemType.ANSWER,
        ChatItemType.IMAGE,
        ChatItemType.ACTION,
        ChatItemType.CONDITION,
        ChatItemType.CHATFLOW,
        ChatItemType.TEXTFIELD,
        ChatItemType.SERVICE,
        ChatItemType.REDIRECT,
        ChatItemType.ANIMATED_SVG,
        ChatItemType.AI_REQUEST
    ],
    configKeys : {
        CHAT_FLOW_ID : "chatFlowID",
        SOS_SERVICE_ID : "sosServiceID",
        MENU_SERVICE_ID : "menuServiceID",
        HEADER_THEME : "headerTheme",
        HEADER_BACKGROUND_COLOUR : "headerBackgroundColour",
        HEADER_TITLE : "headerTitle",
        DEFAULT_USER_ROLE_ID : "defaultUserRoleID",
        DEFAULT_LOCATION_ID : "defaultLocationID",
        INCOMING_EMOTION : "incomingEmotionId",
        WIDGET_ALIGNMENT : "widgetAlignment",
        WIDGET_BORDER_COLOUR : "widgetBorderColour",
        WIDGET_BACKGROUND_COLOUR : "widgetBackgroundColour",
        WIDGET_TEXT_COLOUR : "widgetTextColour",
        WIDGET_TITLE : "widgetTitle",
        WIDGET_SHOW_ICON : "widgetShowIcon",
        WIDGET_SHOW_EMOTIONS : "widgetShowEmotions",
        CHAT_FLOWS : "chatFlows",
        SUPPORT_SECTION_ID : "supportSectionID",
        SUPPORT_SECTION_TITLE : "supportSectionTitle",
        CHAT_SHOW_NAVIGATION : "chatShowNavigation",
        CHAT_SHOW_SOS : "chatShowSos",
        CHAT_SHOW_CHARLEY : "chatShowCharley",
        CHAT_SHOW_QR_LINK : "chatShowQRLink",
        CHAT_SHOW_OVERFLOW : "chatShowOverflow",
        WELCOME_SCREEN_SHOWN : "welcomeScreenShown",
        WELCOME_SCREEN_IMAGE_SHOWN : "welcomeScreenImageShown",
        WELCOME_SCREEN_MESSAGE : "welcomeScreenMessage",
        WELCOME_SCREEN_BUTTON_LABEL : "welcomeScreenButtonLabel",
        WIDGET_ASSISTANT_IMAGE_ID : "widgetAssistantImageID",
        WIDGET_ASSISTANT_IMAGE_URL : "widgetAssistantImageUrl",
        WELCOME_SCREEN_ASSISTANT_IMAGE_ID : "welcomeScreenAssistantImageID",
        WELCOME_SCREEN_ASSISTANT_IMAGE_URL : "welcomeScreenAssistantImageUrl",
        ALLOW_PERSISTENT_STORAGE : "allowPersistentStorage"
    },
    checkChatFlowCompatibility : (data) => {
        let compatible = true;
        const results = [];

        if (data && Array.isArray(data)) {
            data.forEach((item) => {
                let innerResult = PluginUtil._checkChatFlowCompatibilityRecursive(item, results);
                if (!innerResult) {
                    compatible = false;
                }
            });
        }

        return {
            compatible,
            results
        };
    },
    _checkChatFlowCompatibilityRecursive(item, results) {
        let passed = true;

        if (item) {
            if (item.hasOwnProperty("typeID")) {
                const typeID = parseInt(item.typeID);
                if (!PluginUtil.chatFlowItemTypesSupported.includes(typeID)) {
                    passed = false;
                    results.push({
                        id : item.uuid,
                        title : CommonUtil.getOrDefault(item, "nodeText", "N/A"),
                        message : "Unsupported Chat Flow item: " + ChatItemType.getName(typeID)
                    });
                }

                if (typeID === ChatItemType.SERVICE) {
                    const service = CommonUtil.getOrDefault(item, "service", null);
                    if (service) {
                        if (service.hasOwnProperty("typeID")) {
                            const serviceTypeID = parseInt(service.typeID);
                            if (!PluginUtil.serviceTypesSupported.includes(serviceTypeID)) {
                                passed = false;
                                results.push({
                                    id : item.uuid,
                                    title : CommonUtil.getOrDefault(item, "nodeText", "N/A"),
                                    message : ServiceType.getTitle(serviceTypeID) + " is not supported"
                                });
                            }
                        }
                    }
                }
            }

            if (item.hasOwnProperty("children") && Array.isArray(item.children)) {
                item.children.forEach((child) => {
                    let innerResult = PluginUtil._checkChatFlowCompatibilityRecursive(child, results);
                    if (!innerResult) {
                        passed = false;
                    }
                });
            }
        }

        return passed;
    },
    _lastSupportPageTableMode : 1
}